import { ValidationErrors } from 'final-form';
import { EMAIL_FIELD_EMPTY_LABEL, EMAIL_FIELD_VALIDATION_LABEL, OTP_VERIFY_FIELD_LABEL } from './constants';
import { parsePhoneNumber } from 'libphonenumber-js';

import { trimPhoneNumber } from './util';
import { LalaSentry } from '@lendi/lala-utils';

export const MOBILE_ERROR = 'Please provide an Australian mobile phone number';
export const FIRST_NAME_ERROR = 'Please enter your first name';
export const INTL_MOBILE_ERROR = 'Please provide a valid mobile phone number';
const FIELD_PATHS = {
  CODE: () => 'code',
  EMAIL: () => 'email',
  FIRST_NAME: () => 'firstName',
  PHONE: () => 'phoneNumber',
};

export function validateIntlPhone(values: { phoneNumber?: string }): ValidationErrors {
  try {
    const phoneNumberParsed = parsePhoneNumber(values.phoneNumber ?? '');
    if (!phoneNumberParsed.isPossible() || !phoneNumberParsed.isValid()) {
      return { phoneNumber: INTL_MOBILE_ERROR };
    }
  } catch (error) {
    LalaSentry.logError('Exception on validating intl. number', { error });
    return { phoneNumber: INTL_MOBILE_ERROR };
  }
  return {};
}

export function validate(values: { phoneNumber?: string }) {
  const errors: ValidationErrors = {};
  if (!values.phoneNumber || !trimPhoneNumber(values.phoneNumber).match(/^0?4\d{8}$/)) {
    errors.phoneNumber = MOBILE_ERROR;
  }

  return errors;
}

export function validateWithName(values: any) {
  const errors: ValidationErrors = {};
  if (!values[FIELD_PATHS.FIRST_NAME()]) {
    errors[FIELD_PATHS.FIRST_NAME()] = FIRST_NAME_ERROR;
  }

  const phoneErrors = validate(values);

  return { ...phoneErrors, errors };
}

export const validateVerifyCard = (newCustomer: boolean, values: { code?: string; email?: string }) => {
  const errors: ValidationErrors = {};
  if (!values.code || values.code.length !== 6) {
    errors.code = OTP_VERIFY_FIELD_LABEL;
  }
  if (newCustomer && !values.email) {
    errors.email = EMAIL_FIELD_EMPTY_LABEL;
  } else if (
    newCustomer &&
    values.email &&
    !String(values.email)
      .toLowerCase()
      .match(
        /^((?!.*#)([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    errors.email = EMAIL_FIELD_VALIDATION_LABEL;
  }
  return errors;
};
