import { useContext } from 'react';
import parse from 'html-react-parser';
import { TranslationContext } from '../context';
import { TRANSLATION_KEY } from '../context/translation';

export const useTranslate = (translationKeys: TRANSLATION_KEY[]) => {
  const brandedTranslations = useContext(TranslationContext);
  if (!brandedTranslations) throw new Error('@lendi/lala-customer-app: No translations loaded.');

  const translationsToReturn = new Array<string | JSX.Element | JSX.Element[] | undefined>(translationKeys.length);

  translationKeys.forEach((key, index) => {
    const translation = brandedTranslations[key];

    if (typeof translation === 'string') {
      translationsToReturn[index] = translation;
    } else {
      translationsToReturn[index] = translation?.requireParse ? parse(translation.text) : translation?.text;
    }
  });
  return translationsToReturn;
};
