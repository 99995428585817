import { AUSSIE_IS_SCRIPTS, LENDI_IS_SCRIPTS } from '@lendi-ui/scripts';
import Grid from '@lendi-ui/grid';
import { LalaSentry } from '@lendi/lala-utils';
import { SimpleNavbar } from '@lendi/navbar';
import Head from 'next/head';
import * as React from 'react';
import { useTranslate } from '../hooks';
import { GridWrapper, Root, StyledContainer } from './index.style';
import { useSession, Brand, Environment } from '@lendi/lala-react';

type alignmentType = 'center' | 'expand';
interface LayoutProps {
  title?: string;
  alignment?: alignmentType;
  showButtons?: boolean;
}

export const AuthenticationFlowLayout: React.FC<LayoutProps> = ({
  children,
  title,
  alignment = 'center',
  showButtons,
}) => {
  const { brand, logout } = useSession();
  const [siteName] = useTranslate(['SITE_NAME']);
  return (
    <>
      <Head>
        <title>
          {title || 'Sign in'} - {siteName}
        </title>
        {brand === Brand.Lendi
          ? LENDI_IS_SCRIPTS[process.env.ENVIRONMENT as Environment]
          : AUSSIE_IS_SCRIPTS[process.env.ENVIRONMENT as Environment]}
      </Head>

      <Root>
        <SimpleNavbar
          onLogout={logout}
          homeURL="/"
          showContinueAction={false}
          showTalkToExpertCta={!!showButtons}
          showBookanAppointmentCta={!!showButtons}
          onException={(error: Error) => LalaSentry.logToSentry(`Navbar exception: ${error}`)}
        />
        {alignment === 'center' ? (
          <StyledContainer>
            <Grid halign="center">
              <Grid.Unit size={{ xs: 1, sm: 2 / 3, md: 3 / 4, lg: 3 / 5, xl: 1 / 2 }}>{children}</Grid.Unit>
            </Grid>
          </StyledContainer>
        ) : (
          <GridWrapper>{children}</GridWrapper>
        )}
      </Root>
    </>
  );
};
