import { fg } from '@lendi-ui/color';
import Field from '@lendi-ui/field';
import { Email, Person, Smartphone, Lock, Message } from '@lendi-ui/icon';
import { ml, my } from '@lendi-ui/spacing';
import { Input } from '@lendi-ui/text-input';
import { body } from '@lendi-ui/typography';
import * as React from 'react';
import * as RFF from 'react-final-form';
import styled from 'styled-components';
import { LALAAuthError } from '../../state/state';
import getColour from '@lendi-ui/commons/colours';

const PersonIcon = () => (
  <BeforeWrapper>
    <Person color={getColour('fill', 'accent')} width="24px" height="24px" />
  </BeforeWrapper>
);

const PhoneIcon = () => (
  <BeforeWrapper>
    <Smartphone color={getColour('fill', 'accent')} width="24px" height="24px" /> <>+61</>
  </BeforeWrapper>
);

export const MessageIcon = () => (
  <BeforeWrapper>
    <Message color={getColour('fill', 'accent')} width="24px" height="24px" />
  </BeforeWrapper>
);

export interface FirstNameFieldProps {
  value?: string;
  labelText?: string;
  autoFocus?: boolean;
}

export const FirstNameField = ({ labelText, autoFocus, value = '' }: FirstNameFieldProps) => {
  const [fieldValue, setFieldValue] = React.useState(value);
  // test case is not happy with React.ChangeEvent<HTMLInputElement>
  const handleChange = (e: any) => {
    setFieldValue(e.target.value);
  };
  return (
    <RFFFieldWrapper>
      <RFF.Field name="firstName" type="text">
        {({ input, meta }) => (
          <Field {...meta} label={labelText} htmlFor="firstNameInput">
            <Input
              // to pass the UTs
              crossOrigin=""
              {...input}
              enterKeyHint={'enter'}
              type="text"
              isFullWidth
              placeholder="Type your first name here"
              data-testid="request-first-name"
              aria-label="first name"
              size={{ mobile: 'sm', tablet: 'md', desktop: 'md' }}
              autoFocus={autoFocus}
              before={<PersonIcon />}
              onChange={(e: React.ChangeEvent) => {
                input.onChange(e);
                handleChange(e);
              }}
              className="fs-exclude"
              value={fieldValue}
            />
          </Field>
        )}
      </RFF.Field>
    </RFFFieldWrapper>
  );
};

export interface MobileFieldProps {
  error?: LALAAuthError;
  labelText?: string;
  resetAuthError: () => void;
  autoFocus?: boolean;
}

export const MobileField = ({ autoFocus, error, resetAuthError, labelText }: MobileFieldProps) => (
  <RFFFieldWrapper>
    <RFF.Field name="phoneNumber" type="tel">
      {({ input, meta }) => (
        <Field {...meta} label={labelText} htmlFor="phoneNumberInput">
          <Input
            // to pass the UTs
            crossOrigin=""
            {...input}
            enterKeyHint={'enter'}
            type="tel"
            isFullWidth
            data-testid="request-code-phone-number"
            autoComplete="tel"
            autoFocus={autoFocus}
            size={{ mobile: 'sm', tablet: 'md', desktop: 'md' }}
            aria-label="phone number"
            before={<PhoneIcon />}
            onChange={(e: React.ChangeEvent) => {
              if (error) resetAuthError();
              input.onChange(e);
            }}
            className="fs-exclude"
          />
        </Field>
      )}
    </RFF.Field>
  </RFFFieldWrapper>
);

export const RFFFieldWrapper = styled.div`
  ${my('sm')}
  ${fg('shade.500')}
  text-align: center;
`;
// @ts-ignore
export const BeforeWrapper = styled.span`
  white-space: nowrap;
  ${ml('sm')}
  ${body({ size: 'lg', color: 'shade.500' })}
    display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const BeforeIcon = () => (
  <BeforeWrapper>
    <Lock color={getColour('fill', 'accent')} width="1.5rem" height="1.5rem" />
  </BeforeWrapper>
);

export interface FieldProps {
  error?: LALAAuthError;
  resetAuthError?: () => void;
  label?: string;
  autoFocus?: boolean;
}

export const BeforeEmail = () => (
  <BeforeWrapper>
    <Email color={getColour('fill', 'accent')} width="1.5rem" height="1.5rem" />
  </BeforeWrapper>
);

export const EmailField = ({ error, resetAuthError, label }: FieldProps) => (
  <RFFFieldWrapper>
    <RFF.Field name="email">
      {({ input, meta }) => (
        <Field {...meta} label={label} htmlFor="emailInput">
          <Input
            // to pass the UTs
            crossOrigin=""
            {...input}
            enterKeyHint={'enter'}
            placeholder="Enter your email address"
            autoComplete="email"
            type="email"
            data-testid="email-field"
            size="md"
            isFullWidth
            before={<BeforeEmail />}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (error && resetAuthError) resetAuthError();
              input.onChange(e);
            }}
            className="fs-exclude"
          />
        </Field>
      )}
    </RFF.Field>
  </RFFFieldWrapper>
);

export const VerifyOtpField = ({ autoFocus, error, resetAuthError, label }: FieldProps) => {
  return (
    <RFFFieldWrapper>
      <RFF.Field name="code" type="tel">
        {({ input, meta }) => (
          <Field {...meta} label={label} htmlFor="verifyCodeInput">
            <Input
              // to pass the UTs
              crossOrigin=""
              type="tel"
              autoFocus={autoFocus}
              enterKeyHint={'enter'}
              autoComplete="one-time-code"
              size="md"
              isFullWidth
              className="fs-exclude"
              placeholder="Enter 6 digit code"
              before={<MessageIcon />}
              {...input}
              data-testid="verify-code-input"
              onChange={(e: React.ChangeEvent) => {
                if (error && resetAuthError) resetAuthError();
                input.onChange(e);
              }}
            />
          </Field>
        )}
      </RFF.Field>
    </RFFFieldWrapper>
  );
};
