import { Status, useSession } from '@lendi/lala-react';
import { useEffect, useState } from 'react';
import { getLinkedCustomerMobile } from '../api/customer';
import { LalaSentry } from '@lendi/lala-utils';
export enum LinkableError {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  SERVER_ERROR = 'SERVER_ERROR',
}
export const useLinkableCustomerHook = () => {
  const session = useSession();
  const { status } = session;
  const [mobileNumber, setMobileNumber] = useState('');
  const [apiError, setApiError] = useState<LinkableError | undefined>(undefined);
  useEffect(() => {
    LalaSentry.logInfo('Status changed in useLinkableCustomerHook', { status });
    const callLinkedCustomerFn = async () => {
      try {
        const { data } = await getLinkedCustomerMobile(session);
        const { mobileNumber } = data.data;
        setMobileNumber(mobileNumber);
      } catch (err) {
        if (err.message.indexOf('403') > -1) {
          setApiError(LinkableError.PERMISSION_DENIED);
        } else {
          setApiError(LinkableError.SERVER_ERROR);
        }
      }
    };
    (async () => {
      if (status === Status.Authenticated) {
        await callLinkedCustomerFn();
      }
    })();
  }, [status]);
  return { mobileNumber, apiError };
};
